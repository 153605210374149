<script lang="ts" setup>
import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";

const router = useRouter();
const $toast = useToast();
const store = useStore();
const phoneRegex = /^0?(7[0]|8[0-1]|9[0-1])[0-9]{8}$/;
const numbRegex = /^\d+$/;

var home = reactive({
  name: "",
  phone: "",
  email: "",
  address: "",
  rooms: "",
  ac: "",
  appliances: "",
  gps: "",
  land: "",
});

const errors = ref({
  name: false,
  phone: false,
  email: false,
  address: false,
  rooms: false,
  ac: false,
  appliances: false,
  gps: false,
  land: false,
});

const eMsg = ref({
  name: "This field is required",
  phone: "This field is required",
  email: "This field is required",
  address: "This field is required",
  rooms: "This field is required",
  ac: "This field is required",
  appliances: "This field is required",
  gps: "This field is required",
  land: "This field is required",
});

const saveDetails = () => {
  if (home.name == "") {
    errors.value.name = true;
    return;
  } else {
    errors.value.name = false;
  }

  if (home.phone == "") {
    errors.value.phone = true;
    return;
  } else if (!phoneRegex.test(home.phone)) {
    errors.value.phone = true;
    eMsg.value.phone = "Please Enter a valid Phone Number";
    return;
  } else {
    errors.value.phone = false;
  }

  if (home.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !home.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;
  }

  if (home.address == "") {
    errors.value.address = true;
    return;
  } else {
    errors.value.address = false;
  }

  if (home.rooms == "") {
    errors.value.rooms = true;
    return;
  } else if (!numbRegex.test(home.rooms)) {
    errors.value.rooms = true;
    eMsg.value.rooms = "Please enter numbers only";
    return;
  } else {
    errors.value.rooms = false;
  }

  if (home.ac == "") {
    errors.value.ac = true;
    return;
  } else if (!numbRegex.test(home.ac)) {
    errors.value.ac = true;
    eMsg.value.ac = "Please enter numbers only";
    return;
  } else {
    errors.value.ac = false;
  }

  if (home.appliances == "") {
    errors.value.appliances = true;
    return;
  } else {
    errors.value.appliances = false;
  }
  if (home.gps == "") {
    errors.value.gps = true;
    return;
  } else {
    errors.value.gps = false;
  }
  if (home.land == "") {
    errors.value.land = true;
    return;
  } else if (!numbRegex.test(home.land)) {
    errors.value.land = true;
    eMsg.value.land = "Please enter numbers only";
    return;
  } else {
    errors.value.land = false;
  }

  store
    .dispatch("post", {
      endpoint: "/auditForm/home",
      details: {
        name: home.name,
        phone: home.phone,
        email: home.email,
        address: home.address,
        rooms: home.rooms,
        ac: home.ac,
        appliances: home.appliances,
        gps: home.gps,
        land: home.land,
      },
    })
    .then((resp) => {
      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
      useToast().success("Form Submitted Successfully");
    });
};
</script>

<template>
  <div
    class="offcanvas offcanvas-end right-sheet"
    tabindex="-1"
    id="offcanvasRight8"
    aria-labelledby="offcanvasRightLabel8"
  >
    <div class="offcanvas-header border-bottom py-4 d-block">
      <div class="mb-3">
        <button
          type="button"
          class="bg-white me-2"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <Iconify icon="ep:back" />
        </button>
        <span>Back</span>
      </div>

      <div class="d-flex align-items-center justify-content-between">
        <h5 id="offcanvasRightLabel8">TAO Energy Audit Form</h5>
        <div class="home-icon d-flex align-items-center">
          <Iconify icon="ion:home-outline" style="color: #818181" />
          <p class="p-text text-gray ms-2">Home</p>
        </div>
      </div>
    </div>
    <div class="offcanvas-body p-4">
      <form @submit.prevent="saveDetails()">
        <div class="each-field mb-4">
          <label class="mb-2" for="meter"
            >Name <span class="text-danger">*</span></label
          >
          <div>
            <input
              v-model="home.name"
              type="text"
              class="form-control border-0 bg-gray mb-2"
              placeholder="e.g smith wills"
            />
          </div>
          <div v-if="errors.name" class="error-msg">
            {{ eMsg.name }}
          </div>
        </div>

        <div class="each-field mb-4">
          <label for="meter" class="mb-2">
            Phone Number <span class="text-danger">*</span></label
          >
          <div class="input-group mb-3">
            <div class="input-group-text" id="basic-addon1">
              <Iconify
                icon="twemoji:flag-nigeria"
                style="width: 24px; height: 24x"
              /><span class="pe-2" style="border-right: 1px solid gray">
                +234</span
              >
            </div>

            <input
              v-model="home.phone"
              type="tel"
              class="form-control border-0 bg-gray"
              placeholder="81*******"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div v-if="errors.phone" class="error-msg">
            {{ eMsg.phone }}
          </div>
        </div>

        <div class="each-field mb-4">
          <label class="mb-2" for="meter"
            >Email Address <span class="text-danger">*</span></label
          >
          <div>
            <input
              v-model="home.email"
              type="email"
              class="form-control border-0 bg-gray mb-2"
              placeholder="e.g johndoes@gmail.com"
            />
          </div>
          <div v-if="errors.email" class="error-msg">
            {{ eMsg.email }}
          </div>
        </div>

        <div class="each-field mb-4">
          <label for="meter" class="mb-2"
            >Home Address <span class="text-danger">*</span></label
          >
          <div class="input-group mb-3">
            <span class="input-group-text" id="inputGroup-sizing-default"
              ><Iconify
                icon="carbon:location"
                class="pe-2"
                style="
                  width: 30px;
                  height: 30px;
                  color: #818181;
                  border-right: 1px solid #c6c6c6;
                "
              />
            </span>
            <input
              v-model="home.address"
              type="text"
              class="form-control border-0 bg-gray"
              placeholder="Enter Address "
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div v-if="errors.address" class="error-msg">
            {{ eMsg.address }}
          </div>
        </div>

        <div class="each-field mb-4">
          <label class="mb-2" for="meter"
            >Home Size (Number of Bedrooms)
            <span class="text-danger">*</span></label
          >
          <div>
            <input
              v-model="home.rooms"
              type="text"
              class="form-control border-0 bg-gray mb-2"
              placeholder="Your answer"
            />
          </div>
          <div v-if="errors.rooms" class="error-msg">
            {{ eMsg.rooms }}
          </div>
        </div>

        <div class="each-field mb-4">
          <label class="mb-2" for="meter"
            >How many ACs do you want to power during the day
            <span class="text-danger">*</span></label
          >
          <div>
            <input
              v-model="home.ac"
              type="text"
              class="form-control border-0 bg-gray mb-2"
              placeholder="Your answer"
            />
          </div>
          <div v-if="errors.ac" class="error-msg">
            {{ eMsg.ac }}
          </div>
        </div>

        <div class="each-field mb-4">
          <label class="mb-2" for="meter"
            >Do you want to power water heaters, irons, electric stoves,
            electric kettles etc <span class="text-danger">*</span></label
          >

          <div class="d-flex justify-content-start align-items-center">
            <div class="form-check">
              <input
                v-model="home.appliances"
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="true"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                Yes
              </label>
            </div>
            <div class="form-check ms-3">
              <input
                v-model="home.appliances"
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="false"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                No
              </label>
            </div>
          </div>
          <div v-if="errors.appliances" class="error-msg">
            {{ eMsg.appliances }}
          </div>
        </div>
        <div class="each-field mb-4">
          <label class="mb-2" for="meter"
            >GPS Coordinate for your location?
            <span class="text-danger">*</span></label
          >
          <div>
            <input
              v-model="home.gps"
              type="text"
              step="any"
              class="form-control border-gray bg-gray mb-2"
              placeholder="e.g 40.7128, -74.0060"
            />
          </div>
          <div v-if="errors.gps" class="error-msg">
            {{ eMsg.gps }}
          </div>
        </div>

        <div class="each-field mb-4">
          <label class="mb-2" for="meter"
            >Do you have land or roof top space we can use for solar
            installation? (How many square meter)
            <span class="text-danger">*</span></label
          >
          <div>
            <input
              v-model="home.land"
              step="any"
              type="text"
              class="form-control border-gray bg-gray mb-2"
              placeholder="300"
            />
          </div>
          <div v-if="errors.land" class="error-msg">
            {{ eMsg.land }}
          </div>
        </div>

        <div class="d-grid mt-4">
          <button type="submit" class="btn-primary" style="height: 44px">
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>

  <div
    class="offcanvas offcanvas-bottom full-size"
    tabindex="-1"
    id="offcanvasBottomD"
    aria-labelledby="offcanvasBottomLabelD"
  >
    <div class="offcanvas-header border-bottom py-4 d-block">
      <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <button
            type="button"
            class="me-2 back-icon"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <Iconify icon="ep:arrow-left" />
          </button>
          <h6 class="fs-16" id="offcanvasBottomLabelE">
            TAO Energy Audit Form
          </h6>
        </div>

        <div class="home-icon d-flex align-items-center">
          <Iconify icon="ion:home-outline" style="color: #818181" />
          <p class="p-text text-gray ms-2">Home</p>
        </div>
      </div>
    </div>
    <div class="offcanvas-body p-4">
      <form @submit.prevent="saveDetails()">
        <div class="each-field mb-4">
          <label class="mb-2" for="meter"
            >Name <span class="text-danger">*</span></label
          >
          <div>
            <input
              v-model="home.name"
              type="text"
              class="form-control border-0 bg-gray mb-2"
              placeholder="e.g smith wills"
            />
          </div>
          <div v-if="errors.name" class="error-msg">
            {{ eMsg.name }}
          </div>
        </div>

        <div class="each-field mb-4">
          <label for="meter" class="mb-2">
            Phone Number <span class="text-danger">*</span></label
          >
          <div class="input-group mb-3">
            <div class="input-group-text" id="basic-addon1">
              <Iconify
                icon="twemoji:flag-nigeria"
                style="width: 24px; height: 24x"
              /><span class="pe-2" style="border-right: 1px solid gray">
                +234</span
              >
            </div>

            <input
              v-model="home.phone"
              type="tel"
              class="form-control border-0 bg-gray"
              placeholder="81*******"
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div v-if="errors.phone" class="error-msg">
            {{ eMsg.phone }}
          </div>
        </div>

        <div class="each-field mb-4">
          <label class="mb-2" for="meter"
            >Email Address <span class="text-danger">*</span></label
          >
          <div>
            <input
              v-model="home.email"
              type="email"
              class="form-control border-0 bg-gray mb-2"
              placeholder="e.g johndoes@gmail.com"
            />
          </div>
          <div v-if="errors.email" class="error-msg">
            {{ eMsg.email }}
          </div>
        </div>

        <div class="each-field mb-4">
          <label for="meter" class="mb-2"
            >Home Address <span class="text-danger">*</span></label
          >
          <div class="input-group mb-3">
            <span class="input-group-text" id="inputGroup-sizing-default"
              ><Iconify
                icon="carbon:location"
                class="pe-2"
                style="
                  width: 30px;
                  height: 30px;
                  color: #818181;
                  border-right: 1px solid #c6c6c6;
                "
              />
            </span>
            <input
              v-model="home.address"
              type="text"
              class="form-control border-0 bg-gray"
              placeholder="Enter Address "
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div v-if="errors.address" class="error-msg">
            {{ eMsg.address }}
          </div>
        </div>

        <div class="each-field mb-4">
          <label class="mb-2" for="meter"
            >Home Size (Number of Bedrooms)
            <span class="text-danger">*</span></label
          >
          <div>
            <input
              v-model="home.rooms"
              type="text"
              class="form-control border-0 bg-gray mb-2"
              placeholder="Your answer"
            />
          </div>
          <div v-if="errors.rooms" class="error-msg">
            {{ eMsg.rooms }}
          </div>
        </div>

        <div class="each-field mb-4">
          <label class="mb-2" for="meter"
            >How many ACs do you want to power during the day
            <span class="text-danger">*</span></label
          >
          <div>
            <input
              v-model="home.ac"
              type="text"
              class="form-control border-0 bg-gray mb-2"
              placeholder="Your answer"
            />
          </div>
          <div v-if="errors.ac" class="error-msg">
            {{ eMsg.ac }}
          </div>
        </div>

        <div class="each-field mb-4">
          <label class="mb-2" for="meter"
            >Do you want to power water heaters, irons, electric stoves,
            electric kettles etc <span class="text-danger">*</span></label
          >

          <div class="d-flex justify-content-start align-items-center">
            <div class="form-check">
              <input
                v-model="home.appliances"
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="true"
              />
              <label class="form-check-label" for="flexRadioDefault1">
                Yes
              </label>
            </div>
            <div class="form-check ms-3">
              <input
                v-model="home.appliances"
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="false"
              />
              <label class="form-check-label" for="flexRadioDefault2">
                No
              </label>
            </div>
          </div>
          <div v-if="errors.appliances" class="error-msg">
            {{ eMsg.appliances }}
          </div>
        </div>
        <div class="each-field mb-4">
          <label class="mb-2" for="meter"
            >GPS Coordinate for your location?
            <span class="text-danger">*</span></label
          >
          <div>
            <input
              v-model="home.gps"
              type="text"
              step="any"
              class="form-control border-gray bg-gray mb-2"
              placeholder="e.g 40.7128, -74.0060"
            />
          </div>
          <div v-if="errors.gps" class="error-msg">
            {{ eMsg.gps }}
          </div>
        </div>
        <div class="each-field mb-4">
          <label class="mb-2" for="meter"
            >Do you have land or roof top space we can use for solar
            installation? (How many square meter)
            <span class="text-danger">*</span></label
          >
          <div>
            <input
              v-model="home.land"
              type="text"
              class="form-control border-gray bg-gray mb-2"
              placeholder="100"
            />
          </div>
          <div v-if="errors.land" class="error-msg">
            {{ eMsg.land }}
          </div>
        </div>

        <div class="d-grid mt-4">
          <button type="submit" class="btn-primary" style="height: 44px">
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
