<script lang="ts" setup>
import { ref, computed } from "vue";
import { formatDate } from "@/core/utils/helpers";

const props = defineProps({
  apiHomeRes: {},
});

const homes: any = computed(() => {
  return props.apiHomeRes;
});
</script>
<template>
  <div v-if="homes">
    <div
      class="offcanvas offcanvas-end right-sheet"
      tabindex="-1"
      id="offcanvasRight7"
      aria-labelledby="offcanvasRightLabel7"
    >
      <div class="offcanvas-header border-bottom py-4 d-block">
        <div class="mb-3">
          <button
            type="button"
            class="bg-white me-2"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
          >
            <Iconify icon="ep:back" />
          </button>
          <span>Back</span>
        </div>
        <div>
          <div class="d-flex align-items-center justify-content-between">
            <h5 id="offcanvasRightLabel7">Home</h5>
            <p class="text-gray">{{ formatDate(homes.created_at) }}</p>
          </div>
        </div>
      </div>
      <div class="offcanvas-body p-4">
        <div class="each-card mb-4">
          <p class="text-primary mb-3">Contact Person Information</p>
          <div class="card-wrapper border rounded">
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Name</p>
              <p class="p-text">{{ homes.name }}</p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Address</p>
              <p class="p-text">{{ homes.address }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Phone Number</p>
              <p class="p-text">{{ homes.phone }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Email</p>

              <p class="p-text">{{ homes.email }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Number of Rooms</p>

              <p class="p-text">{{ homes.rooms }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Home Appliances</p>

              <p v-if="homes.appliances == true" class="p-text">Yes</p>
              <p v-if="homes.appliances == false" class="p-text">No</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">GPS Coordinate for your location</p>

              <p class="p-text">{{ homes.gps }}</p>
            </div>
            <div
              class="provider p-3 d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Land or roof top space (sqm)</p>

              <p class="p-text">{{ homes.land }}sqm</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="offcanvas offcanvas-bottom full-size"
      tabindex="-1"
      id="offcanvasBottomF"
      aria-labelledby="offcanvasBottomLabelF"
    >
      <div class="offcanvas-header border-bottom mb-4 d-block">
        <div class="d-flex align-items-center justify-content-between">
          <div>
            <h5 id="offcanvasBottomLabelF">Home</h5>
            <p class="text-gray mt-2">{{ formatDate(homes.created_at) }}</p>
          </div>
          <div class="back-icon">
            <button
              type="button"
              class="me-2 btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            ></button>
          </div>
        </div>
      </div>
      <div class="offcanvas-body p-3">
        <div class="each-card mb-4">
          <p class="text-primary mb-3">Contact Person Information</p>
          <div class="card-wrapper border rounded">
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Name</p>
              <p class="p-text">{{ homes.name }}</p>
            </div>

            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Address</p>
              <p class="p-text">{{ homes.address }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Phone Number</p>
              <p class="p-text">{{ homes.phone }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Email</p>

              <p class="p-text">{{ homes.email }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Number of Rooms</p>

              <p class="p-text">{{ homes.rooms }}</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Home Appliances</p>

              <p v-if="homes.appliances == true" class="p-text">Yes</p>
              <p v-if="homes.appliances == false" class="p-text">No</p>
            </div>
            <div
              class="provider p-3 border-bottom d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">GPS Coordinate for your location</p>

              <p class="p-text">{{ homes.gps }}</p>
            </div>
            <div
              class="provider p-3 d-flex justify-content-between align-items-center"
            >
              <p class="text-gray p-text">Land or roof top space (sqm)</p>

              <p class="p-text">{{ homes.land }}sqm</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
