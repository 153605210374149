<template>
  <div class="sidebar-wrapper" :class="showsidebar ? 'showSB' : 'noSB'">
    <div class="top">
      <div class="brand">
        <div
          class="brand-logo d-flex align-items-center justify-content-center"
        >
          <img src="@/assets/images/logo.svg" alt="" />
        </div>
      </div>
      <ul class="menu-items">
        <li class="each-item">
          <router-link
            class="nav-link"
            to="/buy-electricity"
            :class="{ active: $route.name === 'Buy Electricity' }"
          >
            <Iconify icon="ion:flash" />
            <span class="menu-text">Buy Electricity</span>
          </router-link>
        </li>
        <li class="each-item my-4">
          <router-link
            class="nav-link"
            to="/track"
            :class="{ active: $route.name === 'Buy Solar' }"
          >
            <Iconify icon="ph:solar-panel-light" />
            <span class="menu-text">Buy Solar</span>
          </router-link>
        </li>
      </ul>
    </div>
    <div class="bottom">
      <ul class="menu-items mt-5">
        <div @click.prevent="toggleDropdown()" class="c-flex">
          <span class="dropdown-toggle"></span>
          <div class="items each-item d-flex">
            <router-link
              class="nav-link"
              to="#"
              :class="{ active: $route.name === 'My Account' }"
            >
              <Iconify icon="solar:user-linear" />
              <span class="menu-text">My Account</span>
            </router-link>
          </div>
        </div>

        <ul v-if="menudrop" class="menu-body border-0 menu-items text-end">
          <li class="each-item">
            <router-link
              class="nav-link"
              to="/my-profile"
              :class="{ active: $route.name === 'My Profile' }"
            >
              <Iconify icon="solar:user-linear" />
              <span class="menu-text">My Profile</span>
            </router-link>
          </li>
          <li class="each-item">
            <router-link
              class="nav-link"
              to="/beneficiary"
              :class="{ active: $route.name === 'Beneficiary' }"
            >
              <Iconify icon="solar:users-group-rounded-linear" />
              <span class="menu-text">Beneficiary</span>
            </router-link>
          </li>
          <li class="each-item">
            <router-link
              class="nav-link"
              to="/notifications"
              :class="{ active: $route.name === 'Notifications' }"
            >
              <Iconify icon="lets-icons:bell-light" />
              <span class="menu-text">Notifications</span>
            </router-link>
          </li>
          <li class="each-item">
            <router-link
              class="nav-link"
              to="/update-password"
              :class="{ active: $route.name === 'Update Password' }"
            >
              <Iconify icon="mage:security-shield" />
              <span class="menu-text">Update Password</span>
            </router-link>
          </li>
        </ul>
      </ul>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();
const menudrop = ref(false);

const toggleDropdown = () => {
  menudrop.value = !menudrop.value;
};

const showsidebar = computed(() => {
  return store.state.showsidebar;
});
</script>

<style lang="scss" scoped></style>
