<script setup lang="ts">
import { ref, reactive, computed } from "vue";
import { useToast } from "vue-toast-notification";
import { useStore } from "vuex";

const store = useStore();
const $toast = useToast();
const emit = defineEmits(["continue", "guestPurchase"]);
const props = defineProps({
  purchase: Object,
});

const passwordVisible = ref(false);
const loading = ref(false);
const firstModal = ref(null);
const secondModal = ref(null);
const phoneRegex = /^0?(7[0]|8[0-1]|9[0-1])[0-9]{8}$/;

const guestPurchase = () => {
  emit("guestPurchase", props.purchase);
};

const formIsValid = computed(() => {
  return (
    register.email &&
    register.first_name &&
    register.last_name &&
    register.password &&
    register.phone
  );
});
const formFilled = computed(() => {
  return register.email;
});

const formIsFilled = computed(() => {
  return form.email && form.password;
});
const register = reactive({
  email: "",
  first_name: "",
  last_name: "",
  password: "",
  phone: "",
});
const form = reactive({
  email: "",
  password: "",
});

const errors = ref({
  email: false,
  password: false,
  phone: false,
  first_name: false,
  last_name: false,
});
const eMsg = ref({
  email: "This field is required",
  password: "This field is required",
  phone: "This field is required",
  first_name: "This field is required",
  last_name: "This field is required",
});

const handleNextPage = () => {
  if (register.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !register.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;

    hideFirstModal();
    showSecondModal();
  }
};
const hideFirstModal = () => {
  const modal = bootstrap.Modal.getInstance(firstModal.value);
  modal.hide();
};

const showSecondModal = () => {
  const modal = new bootstrap.Modal(secondModal.value);
  modal.show();
};

const handleContinue = () => {
  if (register.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !register.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;
  }

  if (register.first_name == "") {
    errors.value.first_name = true;
    return;
  } else {
    errors.value.first_name = false;
  }
  if (register.last_name == "") {
    errors.value.last_name = true;
    return;
  } else {
    errors.value.last_name = false;
  }

  if (register.phone == "") {
    errors.value.phone = true;
    return;
  } else {
    errors.value.phone = false;
  }

  if (register.phone == "") {
    errors.value.phone = true;
    return;
  } else if (!phoneRegex.test(register.phone)) {
    errors.value.phone = true;
    eMsg.value.phone = "Please Enter a valid Phone Number";
    return;
  } else {
    errors.value.phone = false;
  }

  if (register.password == "") {
    errors.value.password = true;
    return;
  } else {
    errors.value.password = false;
  }

  loading.value = true;
  store.commit("setLoader", true);
  store
    .dispatch("post", {
      endpoint: "/auth/register",
      details: {
        email: register.email,
        first_name: register.first_name,
        last_name: register.last_name,
        password: register.password,
        phone: register.phone,
      },
    })
    .then((resp) => {
      loading.value = false;
      store.commit("setLoader", false);
      store.commit("setUser", resp.data);
      useToast().success("Account created successfully");
      var ad = document.getElementById("exampleModalToggle2");
      var md = window.bootstrap.Modal.getInstance(ad);
      md.hide();
      emit("continue");
    })
    .catch(() => {
      store.commit("setLoader", false);
      loading.value = false;
    });
};

const Login = () => {
  if (form.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !form.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;
  }

  if (form.password == "") {
    errors.value.password = true;
    return;
  } else {
    errors.value.password = false;
  }
  loading.value = true;
  store.commit("setLoader", true);
  store
    .dispatch("post", {
      endpoint: "/auth/login",
      details: { email: form.email, password: form.password },
    })
    .then((resp) => {
      loading.value = false;
      store.commit("setUser", resp.data);
      useToast().success("Login Successful");
      store.commit("setLoader", false);
      var lg = document.getElementById("exampleModalToggle3");
      var loginModal = window.bootstrap.Modal.getInstance(lg);
      loginModal.hide();
      emit("continue");
    })
    .catch(() => {
      loading.value = false;
      store.commit("setLoader", false);
    });
};

const togglePasswordVisibility = () => {
  passwordVisible.value = !passwordVisible.value;
};
</script>

<template>
  <div
    class="modal fade"
    id="exampleModalToggle"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel"
    tabindex="-1"
    ref="firstModal"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-4">
        <div class="modal-header border-0 p-0">
          <div>
            <h1 class="modal-title fs-5" id="exampleModalToggleLabel">
              Create an account to pay
            </h1>
          </div>
          <button
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            style="
              height: 27px;
              width: 27px;
              border-radius: 50%;
              background-color: black;
              color: white;
            "
          >
            <Iconify icon="iconamoon:close-duotone" style="font-size: 18px" />
          </button>
        </div>
        <span class="text-gray">
          Enter your details to create an account with your email address</span
        >
        <div class="modal-body pt-3 px-0">
          <div class="each-field">
            <label class="mb-2" for="email"
              >Email Address <span class="text-danger">*</span></label
            >
            <div>
              <input
                v-model="register.email"
                type="email"
                class="form-control border-0 bg-gray"
                placeholder="e.g joedoe@gmail.com"
              />
            </div>
            <div
              v-if="errors.email"
              class="error-msg text-danger ms-2"
              style="font-size: 12px"
            >
              {{ eMsg.email }}
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 p-0">
          <div class="d-grid m-0">
            <button
              :disabled="!formFilled"
              :class="formFilled ? 'btn-primary' : 'btn-disabled'"
              @click.prevent="handleNextPage()"
              class="p-2 border-0 rounded fw-bold mb-2"
            >
              Continue
            </button>
            <div class="text-center">
              <p class="text-gray">
                Have an account already?
                <span
                  data-bs-target="#exampleModalToggle3"
                  data-bs-toggle="modal"
                  class="text-primary"
                >
                  Sign In</span
                >
              </p>
            </div>
          </div>

          <div class="d-flex justify-content-center align-items-center my-3">
            <div class="line"></div>
            <div class="text-gray mx-1">Or</div>
            <div class="line"></div>
          </div>
        </div>
        <div class="d-grid">
          <button
            @click.prevent="guestPurchase()"
            class="btn-normal d-flex align-items-center justify-content-center"
          >
            <Iconify
              class="primary"
              icon="solar:user-bold"
              style="font-size: 24px"
            />
            <span class="ms-1">Continue as a guest</span>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="exampleModalToggle2"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel2"
    tabindex="-1"
    ref="secondModal"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-4">
        <div class="modal-header border-0 p-0">
          <div>
            <h1 class="modal-title fs-5" id="exampleModalToggleLabel2">
              Create an account
            </h1>
          </div>

          <button
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            style="
              height: 27px;
              width: 27px;
              border-radius: 50%;
              background-color: black;
              color: white;
            "
          >
            <Iconify icon="iconamoon:close-duotone" style="font-size: 18px" />
          </button>
        </div>
        <span class="text-gray">
          Enter your details to create an account with your email address</span
        >
        <div class="modal-body pt-3 px-0">
          <div class="row">
            <div class="col-6">
              <div class="each-field">
                <label class="mb-2" for="email"
                  >Email Address <span class="text-danger">*</span></label
                >
                <div>
                  <input
                    v-model="register.email"
                    type="text"
                    class="form-control border-0 bg-gray"
                    placeholder="e.g joedoe@gmail.com"
                  />
                </div>
                <div
                  v-if="errors.email"
                  class="error-msg text-danger ms-2"
                  style="font-size: 12px"
                >
                  {{ eMsg.email }}
                </div>
              </div>
            </div>
            <div class="col-6">
              <div class="each-field">
                <label class="mb-2" for="email"
                  >First Name <span class="text-danger">*</span></label
                >
                <div>
                  <input
                    v-model="register.first_name"
                    type="email"
                    class="form-control border-0 bg-gray"
                    placeholder="e.g joe"
                  />
                </div>
                <div
                  v-if="errors.first_name"
                  class="error-msg text-danger ms-2"
                  style="font-size: 12px"
                >
                  {{ eMsg.first_name }}
                </div>
              </div>
            </div>
            <div class="col-6 mt-4">
              <div class="each-field">
                <label class="mb-2" for="email"
                  >Last Name <span class="text-danger">*</span></label
                >
                <div>
                  <input
                    v-model="register.last_name"
                    type="text"
                    class="form-control border-0 bg-gray"
                    placeholder="e.g doe"
                  />
                </div>
                <div
                  v-if="errors.last_name"
                  class="error-msg text-danger ms-2"
                  style="font-size: 12px"
                >
                  {{ eMsg.last_name }}
                </div>
              </div>
            </div>
            <div class="col-6 mt-4">
              <div class="each-field mb-3">
                <label for="meter" class="mb-2"
                  >Phone Number <span class="text-danger">*</span></label
                >
                <div>
                  <div class="input-group mb-3">
                    <div class="input-group-text" id="basic-addon1">
                      <Iconify
                        icon="twemoji:flag-nigeria"
                        style="width: 24px; height: 24x"
                      /><span class="pe-2" style="border-right: 1px solid gray">
                        +234</span
                      >
                    </div>

                    <input
                      v-model="register.phone"
                      type="tel"
                      class="form-control border-0 bg-gray"
                      placeholder="081*******"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                  </div>
                  <div
                    v-if="errors.phone"
                    class="error-msg text-danger ms-2"
                    style="font-size: 12px"
                  >
                    {{ eMsg.phone }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="each-field">
                <label class="mb-2" for="email"
                  >Password <span class="text-danger">*</span></label
                >
                <div class="password-container">
                  <input
                    :type="passwordVisible ? 'text' : 'password'"
                    v-model="register.password"
                    class="form-control border-0 bg-gray"
                    placeholder="......"
                  />
                  <span @click="togglePasswordVisibility">
                    <Iconify
                      :icon="
                        passwordVisible
                          ? 'solar:eye-outline'
                          : 'ph:eye-slash-duotone'
                      "
                      class="toggle-eye"
                    />
                  </span>
                </div>
                <div
                  v-if="errors.password"
                  class="error-msg text-danger ms-2 mb-3"
                  style="font-size: 12px"
                >
                  {{ eMsg.password }}
                </div>
              </div>
            </div>
            <p class="text-gray">Password must contain</p>
            <span class="text-gray"> •   8-20 characters </span>
            <span class="text-gray">
              •   Including numbers, letters and special character (!&*/?..)
            </span>
          </div>
        </div>
        <div class="modal-footer border-0 p-0">
          <div class="d-grid m-0">
            <button
              @click.prevent="handleContinue()"
              class="p-2 border-0 rounded fw-bold mb-2"
              :disabled="!formIsValid || loading"
              :class="formIsValid ? 'btn-primary' : 'btn-disabled'"
            >
              <span v-if="loading">Loading...</span>
              <span v-else>Create an account</span>
            </button>
            <div class="text-center">
              <p class="text-gray">
                Have an account already?
                <span
                  data-bs-target="#exampleModalToggle3"
                  data-bs-toggle="modal"
                  class="text-primary"
                >
                  Sign in</span
                >
              </p>
            </div>
          </div>

          <div class="d-flex justify-content-center align-items-center my-3">
            <div class="line"></div>
            <div class="text-gray mx-1">Or</div>
            <div class="line"></div>
          </div>

          <div class="d-grid">
            <button
              @click.prevent="guestPurchase()"
              class="btn-normal d-flex justify-content-center align-items-center"
            >
              <Iconify
                class="primary"
                icon="solar:user-bold"
                style="font-size: 24px"
              />
              <span class="">Continue as a guest</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="exampleModalToggle3"
    aria-hidden="true"
    aria-labelledby="exampleModalToggleLabel3"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-4">
        <div class="modal-header border-0 p-0">
          <div>
            <h1 class="modal-title fs-5" id="exampleModalToggleLabel3">
              Sign In
            </h1>
          </div>

          <button
            type="button"
            data-bs-dismiss="modal"
            aria-label="Close"
            style="
              height: 27px;
              width: 27px;
              border-radius: 50%;
              background-color: black;
              color: white;
            "
          >
            <Iconify icon="iconamoon:close-duotone" style="font-size: 18px" />
          </button>
        </div>
        <span class="text-gray">
          Enter your registered details to continue
        </span>
        <div class="modal-body pt-3 px-0">
          <div class="row">
            <div class="col-12">
              <div class="each-field">
                <label class="mb-2" for="email"
                  >Email Address <span class="text-danger">*</span></label
                >
                <div>
                  <input
                    v-model="form.email"
                    type="text"
                    class="form-control border-0 bg-gray"
                    placeholder="e.g joedoe@gmail.com"
                  />
                </div>
                <div
                  v-if="errors.email"
                  class="error-msg text-danger ms-2 mb-3"
                  style="font-size: 12px"
                >
                  {{ eMsg.email }}
                </div>
              </div>
            </div>
            <div class="col-12">
              <div class="each-field">
                <label class="mb-2" for="email"
                  >Password <span class="text-danger">*</span></label
                >
                <div class="password-container">
                  <input
                    v-model="form.password"
                    :type="passwordVisible ? 'text' : 'password'"
                    class="form-control border-0 bg-gray"
                    placeholder="......"
                  />
                  <span @click="togglePasswordVisibility">
                    <Iconify
                      :icon="
                        passwordVisible
                          ? 'solar:eye-outline'
                          : 'ph:eye-slash-duotone'
                      "
                      class="toggle-eye"
                    />
                  </span>
                </div>
                <div
                  v-if="errors.password"
                  class="error-msg text-danger ms-2 mb-3"
                  style="font-size: 12px"
                >
                  {{ eMsg.password }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer border-0 p-0">
          <div class="d-grid m-0">
            <button
              :class="formIsFilled ? 'btn-primary' : 'btn-disabled'"
              :disabled="!formIsFilled || loading"
              @click.prevent="Login()"
              class="p-2 border-0 rounded fw-bold mb-2"
            >
              {{ loading ? "Loading..." : "Continue" }}
            </button>
            <div class="text-center">
              <p class="text-gray">
                Don't have an account?
                <span
                  data-bs-target="#exampleModalToggle2"
                  data-bs-toggle="modal"
                  class="text-primary"
                >
                  Sign Up</span
                >
              </p>
            </div>
          </div>

          <div class="d-flex justify-content-center align-items-center my-3">
            <div class="line"></div>
            <div class="text-gray mx-1">Or</div>
            <div class="line"></div>
          </div>
          <div class="d-grid">
            <button
              @click.prevent="guestPurchase()"
              class="btn-normal justify-content-center d-flex align-items-center"
            >
              <Iconify
                class="primary"
                icon="solar:user-bold"
                style="font-size: 24px"
              />
              <span class="ms-2">Continue as a guest</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.modal {
  --bs-modal-width: 600px;
  --bs-modal-padding: 1.5rem;

  .modal-header {
    justify-content: space-between;
  }
  .modal-footer {
    display: block;
    width: 100%;
  }
}
</style>
