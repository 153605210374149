import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelRadio as _vModelRadio, withModifiers as _withModifiers, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "offcanvas offcanvas-end right-sheet",
  tabindex: "-1",
  id: "offcanvasRight8",
  "aria-labelledby": "offcanvasRightLabel8"
}
const _hoisted_2 = { class: "offcanvas-header border-bottom py-4 d-block" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = {
  type: "button",
  class: "bg-white me-2",
  "data-bs-dismiss": "offcanvas",
  "aria-label": "Close"
}
const _hoisted_5 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_6 = { class: "home-icon d-flex align-items-center" }
const _hoisted_7 = { class: "offcanvas-body p-4" }
const _hoisted_8 = { class: "each-field mb-4" }
const _hoisted_9 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_10 = { class: "each-field mb-4" }
const _hoisted_11 = { class: "input-group mb-3" }
const _hoisted_12 = {
  class: "input-group-text",
  id: "basic-addon1"
}
const _hoisted_13 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_14 = { class: "each-field mb-4" }
const _hoisted_15 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_16 = { class: "each-field mb-4" }
const _hoisted_17 = { class: "input-group mb-3" }
const _hoisted_18 = {
  class: "input-group-text",
  id: "inputGroup-sizing-default"
}
const _hoisted_19 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_20 = { class: "each-field mb-4" }
const _hoisted_21 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_22 = { class: "each-field mb-4" }
const _hoisted_23 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_24 = { class: "each-field mb-4" }
const _hoisted_25 = { class: "d-flex justify-content-start align-items-center" }
const _hoisted_26 = { class: "form-check" }
const _hoisted_27 = { class: "form-check ms-3" }
const _hoisted_28 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_29 = { class: "each-field mb-4" }
const _hoisted_30 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_31 = { class: "each-field mb-4" }
const _hoisted_32 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_33 = {
  class: "offcanvas offcanvas-bottom full-size",
  tabindex: "-1",
  id: "offcanvasBottomD",
  "aria-labelledby": "offcanvasBottomLabelD"
}
const _hoisted_34 = { class: "offcanvas-header border-bottom py-4 d-block" }
const _hoisted_35 = { class: "d-flex align-items-center justify-content-between" }
const _hoisted_36 = { class: "d-flex align-items-center" }
const _hoisted_37 = {
  type: "button",
  class: "me-2 back-icon",
  "data-bs-dismiss": "offcanvas",
  "aria-label": "Close"
}
const _hoisted_38 = { class: "home-icon d-flex align-items-center" }
const _hoisted_39 = { class: "offcanvas-body p-4" }
const _hoisted_40 = { class: "each-field mb-4" }
const _hoisted_41 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_42 = { class: "each-field mb-4" }
const _hoisted_43 = { class: "input-group mb-3" }
const _hoisted_44 = {
  class: "input-group-text",
  id: "basic-addon1"
}
const _hoisted_45 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_46 = { class: "each-field mb-4" }
const _hoisted_47 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_48 = { class: "each-field mb-4" }
const _hoisted_49 = { class: "input-group mb-3" }
const _hoisted_50 = {
  class: "input-group-text",
  id: "inputGroup-sizing-default"
}
const _hoisted_51 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_52 = { class: "each-field mb-4" }
const _hoisted_53 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_54 = { class: "each-field mb-4" }
const _hoisted_55 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_56 = { class: "each-field mb-4" }
const _hoisted_57 = { class: "d-flex justify-content-start align-items-center" }
const _hoisted_58 = { class: "form-check" }
const _hoisted_59 = { class: "form-check ms-3" }
const _hoisted_60 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_61 = { class: "each-field mb-4" }
const _hoisted_62 = {
  key: 0,
  class: "error-msg"
}
const _hoisted_63 = { class: "each-field mb-4" }
const _hoisted_64 = {
  key: 0,
  class: "error-msg"
}

import { ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useToast } from "vue-toast-notification";


export default /*@__PURE__*/_defineComponent({
  __name: 'AuditHome',
  setup(__props) {

const router = useRouter();
const $toast = useToast();
const store = useStore();
const phoneRegex = /^0?(7[0]|8[0-1]|9[0-1])[0-9]{8}$/;
const numbRegex = /^\d+$/;

var home = reactive({
  name: "",
  phone: "",
  email: "",
  address: "",
  rooms: "",
  ac: "",
  appliances: "",
  gps: "",
  land: "",
});

const errors = ref({
  name: false,
  phone: false,
  email: false,
  address: false,
  rooms: false,
  ac: false,
  appliances: false,
  gps: false,
  land: false,
});

const eMsg = ref({
  name: "This field is required",
  phone: "This field is required",
  email: "This field is required",
  address: "This field is required",
  rooms: "This field is required",
  ac: "This field is required",
  appliances: "This field is required",
  gps: "This field is required",
  land: "This field is required",
});

const saveDetails = () => {
  if (home.name == "") {
    errors.value.name = true;
    return;
  } else {
    errors.value.name = false;
  }

  if (home.phone == "") {
    errors.value.phone = true;
    return;
  } else if (!phoneRegex.test(home.phone)) {
    errors.value.phone = true;
    eMsg.value.phone = "Please Enter a valid Phone Number";
    return;
  } else {
    errors.value.phone = false;
  }

  if (home.email == "") {
    errors.value.email = true;
    return;
  } else if (
    !home.email.match(
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    )
  ) {
    errors.value.email = true;
    eMsg.value.email = "Invalid Email";
    return;
  } else {
    errors.value.email = false;
  }

  if (home.address == "") {
    errors.value.address = true;
    return;
  } else {
    errors.value.address = false;
  }

  if (home.rooms == "") {
    errors.value.rooms = true;
    return;
  } else if (!numbRegex.test(home.rooms)) {
    errors.value.rooms = true;
    eMsg.value.rooms = "Please enter numbers only";
    return;
  } else {
    errors.value.rooms = false;
  }

  if (home.ac == "") {
    errors.value.ac = true;
    return;
  } else if (!numbRegex.test(home.ac)) {
    errors.value.ac = true;
    eMsg.value.ac = "Please enter numbers only";
    return;
  } else {
    errors.value.ac = false;
  }

  if (home.appliances == "") {
    errors.value.appliances = true;
    return;
  } else {
    errors.value.appliances = false;
  }
  if (home.gps == "") {
    errors.value.gps = true;
    return;
  } else {
    errors.value.gps = false;
  }
  if (home.land == "") {
    errors.value.land = true;
    return;
  } else if (!numbRegex.test(home.land)) {
    errors.value.land = true;
    eMsg.value.land = "Please enter numbers only";
    return;
  } else {
    errors.value.land = false;
  }

  store
    .dispatch("post", {
      endpoint: "/auditForm/home",
      details: {
        name: home.name,
        phone: home.phone,
        email: home.email,
        address: home.address,
        rooms: home.rooms,
        ac: home.ac,
        appliances: home.appliances,
        gps: home.gps,
        land: home.land,
      },
    })
    .then((resp) => {
      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
      useToast().success("Form Submitted Successfully");
    });
};

return (_ctx: any,_cache: any) => {
  const _component_Iconify = _resolveComponent("Iconify")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("button", _hoisted_4, [
            _createVNode(_component_Iconify, { icon: "ep:back" })
          ]),
          _cache[22] || (_cache[22] = _createElementVNode("span", null, "Back", -1))
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[24] || (_cache[24] = _createElementVNode("h5", { id: "offcanvasRightLabel8" }, "TAO Energy Audit Form", -1)),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_Iconify, {
              icon: "ion:home-outline",
              style: {"color":"#818181"}
            }),
            _cache[23] || (_cache[23] = _createElementVNode("p", { class: "p-text text-gray ms-2" }, "Home", -1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("form", {
          onSubmit: _cache[10] || (_cache[10] = _withModifiers(($event: any) => (saveDetails()), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_8, [
            _cache[25] || (_cache[25] = _createElementVNode("label", {
              class: "mb-2",
              for: "meter"
            }, [
              _createTextVNode("Name "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_unref(home).name) = $event)),
                type: "text",
                class: "form-control border-0 bg-gray mb-2",
                placeholder: "e.g smith wills"
              }, null, 512), [
                [_vModelText, _unref(home).name]
              ])
            ]),
            (errors.value.name)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(eMsg.value.name), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_10, [
            _cache[27] || (_cache[27] = _createElementVNode("label", {
              for: "meter",
              class: "mb-2"
            }, [
              _createTextVNode(" Phone Number "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_Iconify, {
                  icon: "twemoji:flag-nigeria",
                  style: {"width":"24px","height":"24x"}
                }),
                _cache[26] || (_cache[26] = _createElementVNode("span", {
                  class: "pe-2",
                  style: {"border-right":"1px solid gray"}
                }, " +234", -1))
              ]),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(home).phone) = $event)),
                type: "tel",
                class: "form-control border-0 bg-gray",
                placeholder: "81*******",
                "aria-label": "Username",
                "aria-describedby": "basic-addon1"
              }, null, 512), [
                [_vModelText, _unref(home).phone]
              ])
            ]),
            (errors.value.phone)
              ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(eMsg.value.phone), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_14, [
            _cache[28] || (_cache[28] = _createElementVNode("label", {
              class: "mb-2",
              for: "meter"
            }, [
              _createTextVNode("Email Address "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_unref(home).email) = $event)),
                type: "email",
                class: "form-control border-0 bg-gray mb-2",
                placeholder: "e.g johndoes@gmail.com"
              }, null, 512), [
                [_vModelText, _unref(home).email]
              ])
            ]),
            (errors.value.email)
              ? (_openBlock(), _createElementBlock("div", _hoisted_15, _toDisplayString(eMsg.value.email), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_16, [
            _cache[29] || (_cache[29] = _createElementVNode("label", {
              for: "meter",
              class: "mb-2"
            }, [
              _createTextVNode("Home Address "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", _hoisted_17, [
              _createElementVNode("span", _hoisted_18, [
                _createVNode(_component_Iconify, {
                  icon: "carbon:location",
                  class: "pe-2",
                  style: {"width":"30px","height":"30px","color":"#818181","border-right":"1px solid #c6c6c6"}
                })
              ]),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_unref(home).address) = $event)),
                type: "text",
                class: "form-control border-0 bg-gray",
                placeholder: "Enter Address ",
                "aria-label": "Username",
                "aria-describedby": "basic-addon1"
              }, null, 512), [
                [_vModelText, _unref(home).address]
              ])
            ]),
            (errors.value.address)
              ? (_openBlock(), _createElementBlock("div", _hoisted_19, _toDisplayString(eMsg.value.address), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_20, [
            _cache[30] || (_cache[30] = _createElementVNode("label", {
              class: "mb-2",
              for: "meter"
            }, [
              _createTextVNode("Home Size (Number of Bedrooms) "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(home).rooms) = $event)),
                type: "text",
                class: "form-control border-0 bg-gray mb-2",
                placeholder: "Your answer"
              }, null, 512), [
                [_vModelText, _unref(home).rooms]
              ])
            ]),
            (errors.value.rooms)
              ? (_openBlock(), _createElementBlock("div", _hoisted_21, _toDisplayString(eMsg.value.rooms), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_22, [
            _cache[31] || (_cache[31] = _createElementVNode("label", {
              class: "mb-2",
              for: "meter"
            }, [
              _createTextVNode("How many ACs do you want to power during the day "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_unref(home).ac) = $event)),
                type: "text",
                class: "form-control border-0 bg-gray mb-2",
                placeholder: "Your answer"
              }, null, 512), [
                [_vModelText, _unref(home).ac]
              ])
            ]),
            (errors.value.ac)
              ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(eMsg.value.ac), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_24, [
            _cache[34] || (_cache[34] = _createElementVNode("label", {
              class: "mb-2",
              for: "meter"
            }, [
              _createTextVNode("Do you want to power water heaters, irons, electric stoves, electric kettles etc "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("div", _hoisted_26, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_unref(home).appliances) = $event)),
                  class: "form-check-input",
                  type: "radio",
                  name: "flexRadioDefault",
                  id: "flexRadioDefault1",
                  value: "true"
                }, null, 512), [
                  [_vModelRadio, _unref(home).appliances]
                ]),
                _cache[32] || (_cache[32] = _createElementVNode("label", {
                  class: "form-check-label",
                  for: "flexRadioDefault1"
                }, " Yes ", -1))
              ]),
              _createElementVNode("div", _hoisted_27, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_unref(home).appliances) = $event)),
                  class: "form-check-input",
                  type: "radio",
                  name: "flexRadioDefault",
                  id: "flexRadioDefault2",
                  value: "false"
                }, null, 512), [
                  [_vModelRadio, _unref(home).appliances]
                ]),
                _cache[33] || (_cache[33] = _createElementVNode("label", {
                  class: "form-check-label",
                  for: "flexRadioDefault2"
                }, " No ", -1))
              ])
            ]),
            (errors.value.appliances)
              ? (_openBlock(), _createElementBlock("div", _hoisted_28, _toDisplayString(eMsg.value.appliances), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_29, [
            _cache[35] || (_cache[35] = _createElementVNode("label", {
              class: "mb-2",
              for: "meter"
            }, [
              _createTextVNode("GPS Coordinate for your location? "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_unref(home).gps) = $event)),
                type: "text",
                step: "any",
                class: "form-control border-gray bg-gray mb-2",
                placeholder: "e.g 40.7128, -74.0060"
              }, null, 512), [
                [_vModelText, _unref(home).gps]
              ])
            ]),
            (errors.value.gps)
              ? (_openBlock(), _createElementBlock("div", _hoisted_30, _toDisplayString(eMsg.value.gps), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_31, [
            _cache[36] || (_cache[36] = _createElementVNode("label", {
              class: "mb-2",
              for: "meter"
            }, [
              _createTextVNode("Do you have land or roof top space we can use for solar installation? (How many square meter) "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_unref(home).land) = $event)),
                step: "any",
                type: "text",
                class: "form-control border-gray bg-gray mb-2",
                placeholder: "300"
              }, null, 512), [
                [_vModelText, _unref(home).land]
              ])
            ]),
            (errors.value.land)
              ? (_openBlock(), _createElementBlock("div", _hoisted_32, _toDisplayString(eMsg.value.land), 1))
              : _createCommentVNode("", true)
          ]),
          _cache[37] || (_cache[37] = _createElementVNode("div", { class: "d-grid mt-4" }, [
            _createElementVNode("button", {
              type: "submit",
              class: "btn-primary",
              style: {"height":"44px"}
            }, " Submit ")
          ], -1))
        ], 32)
      ])
    ]),
    _createElementVNode("div", _hoisted_33, [
      _createElementVNode("div", _hoisted_34, [
        _createElementVNode("div", _hoisted_35, [
          _createElementVNode("div", _hoisted_36, [
            _createElementVNode("button", _hoisted_37, [
              _createVNode(_component_Iconify, { icon: "ep:arrow-left" })
            ]),
            _cache[38] || (_cache[38] = _createElementVNode("h6", {
              class: "fs-16",
              id: "offcanvasBottomLabelE"
            }, " TAO Energy Audit Form ", -1))
          ]),
          _createElementVNode("div", _hoisted_38, [
            _createVNode(_component_Iconify, {
              icon: "ion:home-outline",
              style: {"color":"#818181"}
            }),
            _cache[39] || (_cache[39] = _createElementVNode("p", { class: "p-text text-gray ms-2" }, "Home", -1))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_39, [
        _createElementVNode("form", {
          onSubmit: _cache[21] || (_cache[21] = _withModifiers(($event: any) => (saveDetails()), ["prevent"]))
        }, [
          _createElementVNode("div", _hoisted_40, [
            _cache[40] || (_cache[40] = _createElementVNode("label", {
              class: "mb-2",
              for: "meter"
            }, [
              _createTextVNode("Name "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_unref(home).name) = $event)),
                type: "text",
                class: "form-control border-0 bg-gray mb-2",
                placeholder: "e.g smith wills"
              }, null, 512), [
                [_vModelText, _unref(home).name]
              ])
            ]),
            (errors.value.name)
              ? (_openBlock(), _createElementBlock("div", _hoisted_41, _toDisplayString(eMsg.value.name), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_42, [
            _cache[42] || (_cache[42] = _createElementVNode("label", {
              for: "meter",
              class: "mb-2"
            }, [
              _createTextVNode(" Phone Number "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", _hoisted_43, [
              _createElementVNode("div", _hoisted_44, [
                _createVNode(_component_Iconify, {
                  icon: "twemoji:flag-nigeria",
                  style: {"width":"24px","height":"24x"}
                }),
                _cache[41] || (_cache[41] = _createElementVNode("span", {
                  class: "pe-2",
                  style: {"border-right":"1px solid gray"}
                }, " +234", -1))
              ]),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => ((_unref(home).phone) = $event)),
                type: "tel",
                class: "form-control border-0 bg-gray",
                placeholder: "81*******",
                "aria-label": "Username",
                "aria-describedby": "basic-addon1"
              }, null, 512), [
                [_vModelText, _unref(home).phone]
              ])
            ]),
            (errors.value.phone)
              ? (_openBlock(), _createElementBlock("div", _hoisted_45, _toDisplayString(eMsg.value.phone), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_46, [
            _cache[43] || (_cache[43] = _createElementVNode("label", {
              class: "mb-2",
              for: "meter"
            }, [
              _createTextVNode("Email Address "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_unref(home).email) = $event)),
                type: "email",
                class: "form-control border-0 bg-gray mb-2",
                placeholder: "e.g johndoes@gmail.com"
              }, null, 512), [
                [_vModelText, _unref(home).email]
              ])
            ]),
            (errors.value.email)
              ? (_openBlock(), _createElementBlock("div", _hoisted_47, _toDisplayString(eMsg.value.email), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_48, [
            _cache[44] || (_cache[44] = _createElementVNode("label", {
              for: "meter",
              class: "mb-2"
            }, [
              _createTextVNode("Home Address "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", _hoisted_49, [
              _createElementVNode("span", _hoisted_50, [
                _createVNode(_component_Iconify, {
                  icon: "carbon:location",
                  class: "pe-2",
                  style: {"width":"30px","height":"30px","color":"#818181","border-right":"1px solid #c6c6c6"}
                })
              ]),
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[14] || (_cache[14] = ($event: any) => ((_unref(home).address) = $event)),
                type: "text",
                class: "form-control border-0 bg-gray",
                placeholder: "Enter Address ",
                "aria-label": "Username",
                "aria-describedby": "basic-addon1"
              }, null, 512), [
                [_vModelText, _unref(home).address]
              ])
            ]),
            (errors.value.address)
              ? (_openBlock(), _createElementBlock("div", _hoisted_51, _toDisplayString(eMsg.value.address), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_52, [
            _cache[45] || (_cache[45] = _createElementVNode("label", {
              class: "mb-2",
              for: "meter"
            }, [
              _createTextVNode("Home Size (Number of Bedrooms) "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_unref(home).rooms) = $event)),
                type: "text",
                class: "form-control border-0 bg-gray mb-2",
                placeholder: "Your answer"
              }, null, 512), [
                [_vModelText, _unref(home).rooms]
              ])
            ]),
            (errors.value.rooms)
              ? (_openBlock(), _createElementBlock("div", _hoisted_53, _toDisplayString(eMsg.value.rooms), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_54, [
            _cache[46] || (_cache[46] = _createElementVNode("label", {
              class: "mb-2",
              for: "meter"
            }, [
              _createTextVNode("How many ACs do you want to power during the day "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_unref(home).ac) = $event)),
                type: "text",
                class: "form-control border-0 bg-gray mb-2",
                placeholder: "Your answer"
              }, null, 512), [
                [_vModelText, _unref(home).ac]
              ])
            ]),
            (errors.value.ac)
              ? (_openBlock(), _createElementBlock("div", _hoisted_55, _toDisplayString(eMsg.value.ac), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_56, [
            _cache[49] || (_cache[49] = _createElementVNode("label", {
              class: "mb-2",
              for: "meter"
            }, [
              _createTextVNode("Do you want to power water heaters, irons, electric stoves, electric kettles etc "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", _hoisted_57, [
              _createElementVNode("div", _hoisted_58, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[17] || (_cache[17] = ($event: any) => ((_unref(home).appliances) = $event)),
                  class: "form-check-input",
                  type: "radio",
                  name: "flexRadioDefault",
                  id: "flexRadioDefault1",
                  value: "true"
                }, null, 512), [
                  [_vModelRadio, _unref(home).appliances]
                ]),
                _cache[47] || (_cache[47] = _createElementVNode("label", {
                  class: "form-check-label",
                  for: "flexRadioDefault1"
                }, " Yes ", -1))
              ]),
              _createElementVNode("div", _hoisted_59, [
                _withDirectives(_createElementVNode("input", {
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_unref(home).appliances) = $event)),
                  class: "form-check-input",
                  type: "radio",
                  name: "flexRadioDefault",
                  id: "flexRadioDefault2",
                  value: "false"
                }, null, 512), [
                  [_vModelRadio, _unref(home).appliances]
                ]),
                _cache[48] || (_cache[48] = _createElementVNode("label", {
                  class: "form-check-label",
                  for: "flexRadioDefault2"
                }, " No ", -1))
              ])
            ]),
            (errors.value.appliances)
              ? (_openBlock(), _createElementBlock("div", _hoisted_60, _toDisplayString(eMsg.value.appliances), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_61, [
            _cache[50] || (_cache[50] = _createElementVNode("label", {
              class: "mb-2",
              for: "meter"
            }, [
              _createTextVNode("GPS Coordinate for your location? "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[19] || (_cache[19] = ($event: any) => ((_unref(home).gps) = $event)),
                type: "text",
                step: "any",
                class: "form-control border-gray bg-gray mb-2",
                placeholder: "e.g 40.7128, -74.0060"
              }, null, 512), [
                [_vModelText, _unref(home).gps]
              ])
            ]),
            (errors.value.gps)
              ? (_openBlock(), _createElementBlock("div", _hoisted_62, _toDisplayString(eMsg.value.gps), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_63, [
            _cache[51] || (_cache[51] = _createElementVNode("label", {
              class: "mb-2",
              for: "meter"
            }, [
              _createTextVNode("Do you have land or roof top space we can use for solar installation? (How many square meter) "),
              _createElementVNode("span", { class: "text-danger" }, "*")
            ], -1)),
            _createElementVNode("div", null, [
              _withDirectives(_createElementVNode("input", {
                "onUpdate:modelValue": _cache[20] || (_cache[20] = ($event: any) => ((_unref(home).land) = $event)),
                type: "text",
                class: "form-control border-gray bg-gray mb-2",
                placeholder: "100"
              }, null, 512), [
                [_vModelText, _unref(home).land]
              ])
            ]),
            (errors.value.land)
              ? (_openBlock(), _createElementBlock("div", _hoisted_64, _toDisplayString(eMsg.value.land), 1))
              : _createCommentVNode("", true)
          ]),
          _cache[52] || (_cache[52] = _createElementVNode("div", { class: "d-grid mt-4" }, [
            _createElementVNode("button", {
              type: "submit",
              class: "btn-primary",
              style: {"height":"44px"}
            }, " Submit ")
          ], -1))
        ], 32)
      ])
    ])
  ], 64))
}
}

})